@import '../../../styles/common';

  .JmFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100vw;
    min-height: $footerHeight;
    background-color: $appBackgroundColor;
    color: $appFontColor;
    font-size: 0.8em;
    padding: $appPadding;

    .JmFooter-credit {
      margin-left: 3px;
    }
  }