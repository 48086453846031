// Colors
$appBackgroundColor: rgba(37, 37, 37, 1);
$contentBackgroundColorDisabled: lighten($appBackgroundColor, 5%);
$contentBackgroundColor: lighten($appBackgroundColor, 10%);
$contentBackgroundColorHover: lighten($appBackgroundColor, 15%);
$contentBackgroundColorActive: lighten($appBackgroundColor, 20%);
$appFontColor: rgba(240, 240, 240, 1);
$appLinkColor: rgba(138, 237, 141, 1);

// Variables
$appPadding: 20px;
$appBorderRadius: 3px;

// Shell
$headerHeight: 104px;
$footerHeight: 55px;
$mainHeight: calc(100vh - 104px - 55px);
