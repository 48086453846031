@import './styles/common';

* {
  box-sizing: border-box;
  font-family: Lato;
  font-weight: 400;
  font-style: normal;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: $appBackgroundColor;
  overflow: hidden;
}