@import '../../styles/common';

.JmButton {
  width: 250px;
  text-align: center;
  background-color: $contentBackgroundColor;
  padding: $appPadding;
  border-radius: $appBorderRadius;
  cursor: pointer;

  &:not(&--active) {
    &:hover {
      background-color: $contentBackgroundColorHover;
    }
  }

  &--disabled {
    background-color: $contentBackgroundColorHover;
    pointer-events: none;
    text-select: none;
  }

  &--active {
    background-color: $contentBackgroundColorActive;
  }
}