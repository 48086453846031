@import '../../../styles/common';

.JmAbout {
  max-width: 1550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  article {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    .JmAbout-text {
      max-width: 180px;
      text-align: left;
      margin: 20px 30px;

      ul {
        margin: 0;
        padding-left: 20px;
        list-style-type: '\27A2';

        li {
          padding-left: 7px;
        }
      }

      header {
        display: flex;

        h4 {
          padding-top: 3px;
          margin-left: 10px;
        }
      }
    }

    .JmAbout-picture {
      display: block;
      align-self: center;
      width: 110px;
      height: 110px;
      margin: 20px 50px;
      border-radius: $appBorderRadius;
    }
  }
}