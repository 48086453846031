@import '../../styles/common';

.JmCombobox {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-radius: $appBorderRadius;


  .JmCombobox__label {
    padding: 5px 11px;
  }

  .JmComboboxInput {
    display: flex;
    width: 100%;
    border: 1px solid $contentBackgroundColor;
    border-radius: $appBorderRadius;
    cursor: pointer;
    margin-bottom: 1px;

    .JmCombobox__placeholder {
      user-select: none;

      .JmPlaceholder {
        display: inline-block;
        margin: 3px;
        padding: 5px 7px;
      }
    }

    .JmCombobox__chips {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .JmCombobox__menu {

  }
}