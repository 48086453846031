@import '../../styles/common';

.JmDateTimePicker {
  div {
    padding-left: 5px;
  }
  .MuiInputLabel-root,
  .MuiInputBase-input,
  .MuiSvgIcon-root {
    font-family: Lato;
    color: $appFontColor;
  }

  .MuiInputLabel-root {
    font-size: 22px;
    padding-left: 13px;
  }

  .MuiInput-underline:before {
    border-bottom-color: $appFontColor;
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: $appFontColor;
    //border-bottom: 2px solid rgb(0 0 0 / 87%);
  }
}