@import '../../styles/common';

.JmChip {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 7px;
  margin: 3px;
  background-color: $contentBackgroundColor;
  border-radius: $appBorderRadius;
}