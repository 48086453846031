@import '../../styles/common';

.JmDashboard {
  width: 100%;
  height: 100%;
  position: relative;

  #JmMap {
    height: 100%;
  }
  .JmCelestial {
    background-color: transparent;
    position: absolute;
    top: 0;
    z-index: 801;

  }
}