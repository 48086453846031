@import '../../styles/common';

.JmMenu {
  display: flex;
  flex-direction: column;
  //background-color: $contentBackgroundColor;
  padding: 5px $appPadding;
  border: 1px solid $contentBackgroundColor;
  border-radius: $appBorderRadius;

  .JmMenuItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding: 5px 0;

    //background-color: $contentBackgroundColor;
    border-radius: $appBorderRadius;
    cursor: pointer;

    .JmCheckobox {
      width: 24px;
      height: 24px;
    }

    .JmLabel {

    }
  }
}