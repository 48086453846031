@import '../../styles/common';

.JmCelestial {
  width: 100%;
  height: 100%;
  //min-height: 645px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  background-color: $appBackgroundColor;
  padding: $appPadding;
  gap: 15px;
  pointer-events: none;

  .JmCelestial__filters {
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 5px 15px;
    gap: 20px;
    background-color: $contentBackgroundColor;
    pointer-events: auto;
  }
}