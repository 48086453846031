@import '../../styles/common';

.JmListItem {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: $appFontColor;
  margin-bottom: 20px;

  .JmListItem-avatar {
    width: 200px;
    height: 200px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: $appBorderRadius;

    img {
      max-width: 200px;
      max-height: 200px;
      border-radius: $appBorderRadius;
    }
  }

  .JmListItem-details {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;

    .JmListItem-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 18px;

      .JmListItem-name {
        display: block;
        margin: 0 0 10px;
        text-align: left;
      }

      .JmListItem-time {
        display: block;
        margin: 0;
        text-align: left;
        font-size: 14px;
      }
    }

    .JmListItem-description {
      width: 200px;
      display: block;
      margin: 10px 0;
    }

    .JmListItem-link, .JmLink {
      width: 200px;
      text-align: left;
      font-size: 14px;
    }
  }
}